<template>
    <label class="form__label pointer" :class="{ active: check }">
        <input
          :name="name"
          type="radio"
          :value="defaultValue"
          :checked="check"
          @change="setValue"
          hidden="hidden"
        >
        <span class="form__checkerRadio"></span>
        <span class="text_smd">{{ label }}</span>
    </label>
</template>

<script>
export default {
  name: 'custom-radio',
  props: ['value', 'label', 'defaultValue', 'name'],
  computed: {
    check () {
      return String(this.value) === String(this.defaultValue)
    }
  },
  methods: {
    setValue (event) {
      this.$emit('input', String(event.target.value))
    }
  }
}
</script>