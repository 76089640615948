<template>
    <form @submit="$event.preventDefault()">
        <div class="form__wrapper">
            <div class="form__mediaBox image-checkbox">
                <CheckboxImage
                    :name="formData.name"
                    :default-value="1"
                    label="С отделкой"
                    image="/images/quiz/with.jpg"
                    v-model="value"
                ></CheckboxImage>
                <CheckboxImage
                    :name="formData.name"
                    :default-value="0"
                    label="Без отделки"
                    image="/images/quiz/without_finishing.jpg"
                    v-model="value"
                ></CheckboxImage>
            </div>
        </div>

        <div class="content__buttonBox">
            <button
                v-if="!isFirst"
                @click="$store.commit('prev')"
                class="button_def button__size button__type_2"
            >Назад
            </button>

            <div class="growOn"></div>

            <button
                :disabled="!isValid"
                @click="$store.commit('next')"
                class="button_def button__size button__type_1 text_md medium "
            >
                {{ isLast ? 'Показать результат' : 'Следующий вопрос' }}
            </button>
        </div>
    </form>
</template>

<script>
  import {mapGetters, mapState} from 'vuex';
  import CheckValueMixin from '@/modules/quiz/mixins/CheckValueMixin'
  import Checkbox from './forms/Checkbox';
  import Radio from './forms/Radio';
  import CheckboxImage from './forms/CheckboxImage';

  export default {
    name: 'dynamic-form',
    mixins: [CheckValueMixin],
    components: {
      Checkbox,
      Radio,
      CheckboxImage
    },
    computed: {
      ...mapGetters({
        formData: 'getForm',
        isLast: 'isLast',
        isFirst: 'isFirst'
      }),
      ...mapState({
        form: 'form',
        step: 'step'
      }),
      value: {
        get() {
          return this.form[this.formData.name];
        },
        set(value) {
          this.$store.commit('setValue', {
            name: this.formData.name,
            value,
          });
        }
      },
      isValid() {
        const thisValue = this.form[this.formData.name];

        if (this.formData.type === 'checkbox' && thisValue) {
          return thisValue.length > 0;
        } else {
          return thisValue;
        }
      }
    }
  }
</script>