<template>
    <div
        class="poll-border bl-theme-interactive br_2 c_p mb_10"
        :class="{ active: check }"
    >
        <label class="checkbox_item checker_inline">
            <div class="p_14">
                <input
                    :name="name"
                    type="checkbox"
                    :value="defaultValue"
                    :checked="check"
                    @change="setValue"
                >
                <span class="checker_item mt_14 ml_14"></span>
                <span class="checkbox_item_title">{{ label }}</span>
            </div>
        </label>
    </div>
</template>

<script>
  export default {
    name: 'custom-checkbox',
    props: ['value', 'label', 'defaultValue', 'name'],
    computed: {
      check() {
        return Array.isArray(this.value) && this.value.indexOf(String(this.defaultValue)) !== -1;
      }
    },
    methods: {
      setValue(event) {
        let value = Array.isArray(this.value) ? [...this.value] : [];
        let index = value.indexOf(String(this.defaultValue));

        if (index === -1) {
          value.push(String(event.target.value));
        } else {
          value.splice(index, 1);
        }

        this.$emit('input', value);
      }
    }
  }
</script>