<script>
  export default {
    methods: {
      check(value, input) {
        if (Array.isArray(value) && value.indexOf(input) !== -1) {
          return true;
        }

        return value === input;
      }
    }
  }
</script>