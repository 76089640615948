
// директива для обработки события свайпа
// можно использовать как директиву в любом теге:
// <div v-touch="{ right: myFunction1, left: myFunction2 }">

export default {
    update(el, binding) {
        if (typeof window !== "undefined") {
            let initialPoint;
            let finalPoint;
            el.ontouchstart = (event) => {
                event.preventDefault();
                event.stopPropagation();
                initialPoint = event.changedTouches[0];
            };
            el.ontouchend = (event) => {
                event.preventDefault();
                event.stopPropagation();
                finalPoint = event.changedTouches[0];
                let xAbs = Math.abs(initialPoint.pageX - finalPoint.pageX);
                let yAbs = Math.abs(initialPoint.pageY - finalPoint.pageY);
                if (xAbs > 40 || yAbs > 40) {
                    if (xAbs > yAbs) {
                        if (finalPoint.pageX < initialPoint.pageX) {
                            /*СВАЙП ВЛЕВО*/
                            // console.debug('** СВАЙП ВПРАВО **', event.target.classList);
                            const method = binding.value.left;
                            method();
                        } else {
                            /*СВАЙП ВПРАВО*/
                            // console.debug('** СВАЙП ВПРАВО **', event.target.classList);
                            const method = binding.value.right;
                            method();
                        }
                    } else {
                        if (finalPoint.pageY < initialPoint.pageY) {
                            /*СВАЙП ВВЕРХ*/
                            // console.debug('** СВАЙП ВВЕРХ **', event.target.classList);
                            const method = binding.value.top;
                            method();
                        } else {
                            /*СВАЙП ВНИЗ*/
                            // console.debug('** СВАЙП ВНИЗ **', event.target.classList);
                            const method = binding.value.bottom;
                            method();
                        }
                    }
                }
            };
        }
    }
}
