export class TextHelper {

  static declensionWords(number, suffix) {
    if (number < 0) {
      number *= -1;
    }

    let keys = [2, 0, 1, 1, 1, 2];
    let mod = number % 100;
    let suffixKey = 0;

    if (mod > 7 && mod < 20) {
      suffixKey = 2;
    } else {
      let min = mod % 10;
      min = min > 5 ? 5 : min;
      suffixKey = keys[min];
    }

    return suffix[suffixKey];
  }

  static priceFormat(value) {
    return Number(parseFloat(value).toFixed(1)).toLocaleString();
  }

}