<template>
    <div ref="modal" class="modal fade layoutSize layout pAbs modal__container" data-backdrop="static">
        <div class="modal__wrapper modal-dialog">
            <slot></slot>
        </div>
    </div>
</template>

<script>
  import Modal from '@/modules/UI/modal/Modal'

  export default {
    name: "Modal",
    mixins: [Modal],
  }
</script>